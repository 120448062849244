import { createGlobalStyle } from "styled-components"
import { lightTheme } from "./theme"
import { rgba } from "polished"

import externalLinkSVG from "../images/external-link.svg"
export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Creo';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Creo/3AE5F9_0_0.eot');
    src: url('../../fonts/Creo/3AE5F9_0_0.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Creo/3AE5F9_0_0.woff2') format('woff2'),
      url('../../fonts/Creo/3AE5F9_0_0.woff') format('woff'),
      url('../../fonts/Creo/3AE5F9_0_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Creo';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Creo/3AE5F9_1_0.eot');
    src: url('../../fonts/Creo/3AE5F9_1_0.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Creo/3AE5F9_1_0.woff2') format('woff2'),
      url('../../fonts/Creo/3AE5F9_1_0.woff') format('woff'),
      url('../../fonts/Creo/3AE5F9_1_0.ttf') format('truetype');
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    background: ${lightTheme.body};
    color: ${lightTheme.text};
    @media (prefers-color-scheme: dark) {
    }
    height: 100vh;
    margin: 0;
    padding: 0;
    transition: all 0.25s linear;
    a, a:visited {
      text-decoration: none;
      color: ${lightTheme.primary};
      transition: background-color .2s ease-out
    }
    a:hover, a:focus {
      color: ${lightTheme.text};
      background-color: ${lightTheme.primaryAlt}
    }
    a[target="_blank"]::before {
      content: '';
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
      mask-image: url(${externalLinkSVG});
      background: ${lightTheme.primaryAlt};
      line-height: 0;
      margin: 0 .3em 0 0
    }
    a[target="_blank"]:hover::before {
      background-color: ${lightTheme.text};
    }
  }
  .button, 
  button {
    cursor: pointer;
    display: inline-flex;
    padding: 0.5em 2em;
    text-align: center;
    white-space: nowrap;
    border-radius: 0.25em;
    border: 1px solid transparent;
    font-weight: bold;
    &.success {
      border-color: ${rgba(lightTheme.success, 0.8)};
      background-color: ${rgba(lightTheme.success, 1)};
      color: white;
      &:hover {
        background-color: ${rgba(lightTheme.success, 0.6)};
      }
    }
  }
  ul {
    li {
      margin: 0
    }
  }
  @media (max-width: 40rem) {
    h1 {
      font-size: 1.8rem
    }
    h2 {
      font-size: 1.4rem
    }
    h3 {
      font-size: 1.3rem
    }
  }
`
export default GlobalStyles
