/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import GlobalStyles from "../utils/global"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import styled from "styled-components"

const Grid = styled.div`
  gap: 2rem;
  padding: 2rem;
  display: grid;
  min-height: 100vh;
  grid-template-areas:
    "header"
    "image"
    "main"
    "footer";
  @media (min-width: 60rem) {
    grid-template-areas:
      "header header"
      "image main"
      "footer footer";
    grid-template-columns: 3fr minmax(10rem, 30rem);
    grid-template-rows: auto 1fr auto;
  }
`
const Layout = ({ children }) => {
  const { contentYaml } = useStaticQuery(graphql`
    {
      contentYaml(config: { eq: "site" }) {
        metaData {
          title
        }
      }
    }
  `)
  const { metaData } = contentYaml
  return (
    <Grid>
      <Header siteTitle={metaData.title} />
      <>{children}</>
      <Footer siteTitle={metaData.title} />
      <GlobalStyles />
    </Grid>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
