import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import NavItems from "./nav/nav"
import Container from "../components/container"

const FooterEl = styled.footer`
  grid-area: footer;
`

const FooterNav = styled.nav`
  display: flex;
  a {
    margin-right: 0.5em;
    display: inline-block;
  }
`

const Footer = ({ siteTitle }) => (
  <FooterEl>
    <Container>
      {new Date().getFullYear() + " "}
      <Link to="/">{siteTitle}</Link>
      <FooterNav>
        <NavItems navPos="footer" />
      </FooterNav>
    </Container>
  </FooterEl>
)

export default Footer
