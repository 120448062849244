import React, { useState } from "react"
import { Link } from "gatsby"
import NavItems from "./nav/nav"
import Container from "../components/container"
import styled from "styled-components"
import { lightTheme } from "../utils/theme"
import { rgba } from "polished"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-solid-svg-icons"

import Logo from "../../content/assets/llo-logo.svg"

const HeaderEl = styled.header`
  grid-area: header;
`
const Brand = styled(Link)`
  display: block;
  flex-shrink: 0;
  img {
    margin: 0;
  }
  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
`
const HeaderNav = styled.nav`
  @media (max-width: 40rem) {
    display: none;
    flex: 1;
    &.open {
      display: block;
    }
  }
  display: flex;
  align-items: center;
  margin-left: auto;
  a {
    display: inline-block;
    text-decoration: none;
    padding: 0.25em 1em;
    margin: 0 0.1em;
    text-align: center;
    @media (max-width: 40rem) {
      display: block;
      background-color: #00000010;
      margin: 0.25em 0;
      padding: 0.5em 1em;
    }

    &.active {
      background-color: ${lightTheme.primaryAlt};
      color: white;
    }
    &:hover {
      color: ${lightTheme.primaryAlt};
      background-color: ${rgba(lightTheme.primaryAlt, 0.25)};
    }
  }
`
const NavToggle = styled.div`
  justify-self: end;
  @media (min-width: 40rem) {
    display: none;
  }
  margin: 0;
  padding: 1em;
  cursor: pointer;
`

export default () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <HeaderEl>
      <Container flex>
        <Brand to="/" title="zurück zur Startseite">
          <img src={Logo} width={200} alt="Logo Logopädie Ludwig Ott" />
        </Brand>
        <NavToggle
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-controls="navbarHeader"
          aria-expanded={isMenuOpen}
          aria-label="Navigation toggle"
        >
          <FontAwesomeIcon icon={faBars} />
        </NavToggle>
        <HeaderNav id="navbarHeader" className={isMenuOpen && "open"}>
          <NavItems navPos="header" />
        </HeaderNav>
      </Container>
    </HeaderEl>
  )
}
