import styled from "styled-components"

const containerWidth = size => {
  switch (size) {
    case "narrow":
      return "35rem"
    case "wide":
      return "60rem"
    default:
      return "none"
  }
}

const Container = styled.div`
  display: ${props => (props.flex ? "flex" : "block")};
  margin: 0 auto;
  max-width: ${({ size }) => containerWidth(size)};
  justify-content: space-between;
  flex-wrap: wrap;
`
export default Container
