import { useStaticQuery, graphql } from "gatsby";

export default urlToFind => {
  if (typeof window !== "undefined") {
    const imageData = useStaticQuery(
      graphql`
        query Images {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fixed(width: 2100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      `
    );

    const image = imageData.images.edges.find(n => {
      let fn = urlToFind.replace("../", "");
      return n.node.relativePath.includes(fn);
    });
    if (!image) {
      return null;
    }

    return (
      "https://" + window.location.host + image.node.childImageSharp.fixed.src
    );
  }
};
