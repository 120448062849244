import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"

const Nav = ({ navPos }) => (
  <StaticQuery
    query={graphql`
      query {
        header: allMarkdownRemark(
          filter: {
            frontmatter: { type: { eq: "page" }, nav: { eq: "header" } }
          }
          sort: { order: DESC, fields: frontmatter___priority }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                navigationTitle
                type
                path
              }
            }
          }
        }
        footer: allMarkdownRemark(
          filter: {
            frontmatter: { type: { eq: "page" }, nav: { eq: "footer" } }
          }
          sort: { order: DESC, fields: frontmatter___priority }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                navigationTitle
                type
                path
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {navPos === "header" &&
          data.header.edges.map(page => (
            <Link
              activeClassName="active"
              to={page.node.frontmatter.path}
              key={page.node.id}
              title={page.node.frontmatter.title}
            >
              {page.node.frontmatter.navigationTitle}
            </Link>
          ))}
        {navPos === "footer" &&
          data.footer.edges.map(page => (
            <Link
              activeClassName="active"
              to={page.node.frontmatter.path}
              key={page.node.id}
              title={page.node.frontmatter.title}
            >
              {page.node.frontmatter.navigationTitle}
            </Link>
          ))}
      </>
    )}
  />
)

export default Nav
