export const lightTheme = {
  body: "#FFF",
  text: "#333",
  primary: "#3aaa35",
  primaryAlt: "#009640",
  purple: "#e6007e",
  success: "#00B368",
  toggleBorder: "#FFF",
  gradient: "linear-gradient(#39598A, #79D7ED)",
}

export const darkTheme = {
  body: "#333",
  text: "#FFF",
  primary: "#3aaa35",
  primaryAlt: "#009640",
  success: "#7EE6AE",
  toggleBorder: "#6B8096",
  gradient: "linear-gradient(#091236, #1E215D)",
}
